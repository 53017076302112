import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import VueToast from 'vue-toast-notification';
import "vue-toast-notification/dist/theme-default.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.js";
import vSelect from "vue-select";
import moment from 'moment';
import ImageUploader from 'vue-image-upload-resize'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import rate from 'vue-rate'
import 'vue-rate/dist/vue-rate.css'
import Meta from 'vue-meta'


// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faChevronLeft, faChevronRight, faAngleUp, faAngleDown, faHome, faSignInAlt, faSignOutAlt, faCalendarAlt, faExclamationTriangle, faCheckCircle, faLongArrowAltRight, faUsers, faTrash, faInfoCircle, faSearch, faBars, faFile, faComments, faExclamation, faPaperPlane, faArrowLeft, faUserEdit } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add([ faChevronLeft, faChevronRight, faAngleUp, faAngleDown, faHome, faSignInAlt, faSignOutAlt, faCalendarAlt, faExclamationTriangle, faCheckCircle, faLongArrowAltRight, faUsers, faTrash, faInfoCircle, faSearch, faBars, faFile, faComments, faExclamation, faPaperPlane, faArrowLeft, faUserEdit ])
// Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component("v-select", vSelect);

Vue.use(ImageUploader);
Vue.use(rate);
Vue.use(VueToast, {
  position: "top-right"
})
Vue.use(VueTimepicker);
require("./assets/css/main.scss");
Vue.use(Meta)


Vue.prototype.moment = moment
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
