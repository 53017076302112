<template>
	<div class="home">

		<div v-if="isPreLoad" style="height:100vh; display:flex; justify-content: center; align-items: center;">
			<div class="hollow-dots-spinner">
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
			</div>
		</div>

		<div class="container login">
			<form class="form-signin card p-5">
				<h1>Login</h1>
				<div class="form-group mt-4">
					<label for="loginEmail" v-if="translator.email" v-cloak>{{ translator.email }}</label>
					<label for="loginEmail" v-else v-cloak>Email</label>
					<input type="email" class="form-control" id="loginEmail" v-model="loginForm.loginMail">
				</div>
				<div class="form-group">
					<label for="loginPassword" v-if="translator.reserva" v-cloak>{{ translator.contrasenya }}</label>
					<label for="loginPassword" v-else v-cloak>Password</label>
					<input type="text" class="form-control" id="loginPassword" v-model="loginForm.loginPassword">
				</div>
				<span v-cloak>{{ errorLogin.lenght }}</span>
				<div v-if="errorLogin.length > 0" class="alert alert-danger mb-3" role="alert" v-cloak>
					<ol>
						<li v-for="(err, index) in errorLogin" :key="index">{{ err }}</li>
					</ol>
				</div>
				<button type="submit" v-if="translator.entrar" @click.prevent="login()" class="btn btn-primary" v-cloak>{{
					translator.entrar }}</button>
				<button type="submit" v-else @click.prevent="login()" class="btn btn-primary" v-cloak>Enter</button>
				<div class="form-group d-flex align-items-center mt-3">
					<input id="sesion" v-model="iniSesion" type="checkbox" class="mr-1"> <label for="sesion">Keep me logged in</label>
				</div>

			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: 'Home',

	computed: {
		...mapState("utils", ["translator"]),
	},

	data() {
		return {
			isPreLoad: false,
			iniSesion: false,
			loginForm: {
				loginMail: '',
				loginPassword: '',
			},
			errorLogin: [],
		};
	},

	async created() {
		try {
			this.isPreLoad = true;
			//await this.$store.dispatch("utils/getTranslator", { usr: this.$route.query.id, lang: 2 });

			if (localStorage.getItem('iniSesion')) {
				const url = new URL(localStorage.getItem('iniSesion'))
				this.$router.push(`${url.pathname}${url.search}`)
			} else {
				this.isPreLoad = false;
			}

		} catch (error) {
			console.error(error)
		}
	},

	methods: {
		async login() {
			this.errorLogin = []
			let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

			regEmail.test(this.loginForm.loginMail) ? null : this.errorLogin.push('Missing email');
			this.loginForm.loginPassword === '' ? this.errorLogin.push('Missing booking') : '';

			if (this.errorLogin.length === 0) {
				await this.$store.dispatch("utils/getLogin", {
					email: this.loginForm.loginMail,
					contrasenya: this.loginForm.loginPassword,
					iniSesion: this.iniSesion,
					vm: this,
					msn: 'Wrong email or password'
				});
			}
		},
	}
}
</script>

<style scoped>
	.home {	
		min-height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	h1 {
		font-size: 2em;
	}
	.login {
		max-width: 90%;
	}
	@media (min-width: 620px) {
		.login {
			max-width: 550px;
		}
	}
	.login label{
		font-size: 1.2em;
	}
	button {
		font-size: 1.2em;
	}
	input[type="text"], 
	input[type="email"] {
		height: 40px;
      background-color: #f9f9f9;
    }
</style>
