import Vue from 'vue'
import Vuex from 'vuex'

import utils from './utils'
import checkin from './checkin'
import guest from './guest'
import service from './service'
import owner from './owner'
import cleaning from './cleaning'
import manager from './manager'

Vue.use(Vuex)



const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        utils,
        checkin,
        guest,
        service,
        owner,
        cleaning,
        manager
    }
});

export default store;

export function formatDate(date) {
    const allMonths = this.translator.monthShort;
    const allWeekdays = this.translator.weekdayShort;
    const months = [
        allMonths[0],
        allMonths[1],
        allMonths[2],
        allMonths[3],
        allMonths[4],
        allMonths[5],
        allMonths[6],
        allMonths[7],
        allMonths[8],
        allMonths[9],
        allMonths[10],
        allMonths[11],
    ];
    const week = [
        allWeekdays[0],
        allWeekdays[1],
        allWeekdays[2],
        allWeekdays[3],
        allWeekdays[4],
        allWeekdays[5],
        allWeekdays[6],
    ];

    let long_date = "";
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);

    //console.log(date + ', day: ' + day + ', month: ' + month + ', year: ' + year);

    let new_date = new Date(date);
    let day_week = new_date.getUTCDay();
    long_date =
        week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

    return long_date;
}

