import postData from '../assets/js/fetch.js';
import router from '../router';

let dev;
process.env.NODE_ENV === 'production' ? dev = "" : dev = "https://xapp.icnea.net";

const state = {
    loginUrl: '',
    user: '',
    translator: '',
    empresa: '',
};

const mutations = {
    setLogin(state, loginUrl) {
        state.loginUrl = loginUrl;
    },
    setUser(state, user) {
        state.user = user;
    },
    setTranslator(state, translator) {
        state.translator = translator;
    },
    setEmpresa(state, empresa) {
        state.empresa = empresa
    },
};

const actions = {

    getLogin({ commit }, { usr, email, contrasenya, iniSesion, vm, msn }) {
        postData(dev + "/WebService.asmx/Login", { usr, email, contrasenya })
        .then((data) => {

            if (data.d != '') {
                const url = new URL(data.d);
                iniSesion ? localStorage.setItem('iniSesion', url) : null;
                router.push( `${url.pathname}${url.search}` )
            } else {
                vm.$toast.error(msn);
            }

            console.log('Login', data.d);
            commit("setLogin", data.d);
        });
    },

    getUser({ commit }, { usr }) {
        postData(dev + "/WebService.asmx/Usuari", { usr })
        .then((data) => {  
            console.log('Usuari', JSON.parse(data.d).Response);   
            const lang = JSON.parse(data.d).Response.user_language
            commit("setUser", JSON.parse(data.d).Response);
            sessionStorage.setItem('user', JSON.stringify(JSON.parse(data.d).Response));
            
            if (sessionStorage.getItem("diccionario") == undefined) {
                postData(dev + "/WebService.asmx/Diccionari", { 
                    usr, 
                    idioma: lang || 2 
                })
                .then((data) => {
                    console.log('Diccionari', JSON.parse(data.d).Diccionari);
                    sessionStorage.setItem('diccionario', JSON.stringify(JSON.parse(data.d).Diccionari));
                    commit("setTranslator", JSON.parse(data.d).Diccionari);
                }); 
            } else {
                commit("setTranslator", JSON.parse(sessionStorage.getItem("diccionario")));
            }
        });
    },

    getTranslator({ commit }, { usr, lang }) {
        if ( usr === undefined ) {
            console.log('No user');
            return;
        }
        
        postData(dev + "/WebService.asmx/Diccionari", { 
            usr, 
            idioma: lang || 2 
        })
        .then((data) => {
            console.log('Diccionari', JSON.parse(data.d).Diccionari);
            sessionStorage.setItem('diccionario', JSON.stringify(JSON.parse(data.d).Diccionari));
            commit("setTranslator", JSON.parse(data.d).Diccionari);
        });
    },
    getEmpresa({ commit }, { usr }) {
        postData(dev + "/WebService.asmx/Empresa", { usr })
        .then((data) => {     
            console.log('Empresa', data.d);
            commit('setEmpresa', data.d);
        })
    },
};

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};