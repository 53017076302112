import postData from '../assets/js/fetch.js';

let dev;
process.env.NODE_ENV === 'production' ? (dev = '') : (dev = 'https://xapp.icnea.net');

const compare = (a, b) => a.getTime() >= b.getTime();

const formatDate = (date) => {
	const allMonths = JSON.parse(sessionStorage.getItem('diccionario')).monthNames;
	const allWeekdays = JSON.parse(sessionStorage.getItem('diccionario')).weekdayShort;
	const months = [
		allMonths[0],
		allMonths[1],
		allMonths[2],
		allMonths[3],
		allMonths[4],
		allMonths[5],
		allMonths[6],
		allMonths[7],
		allMonths[8],
		allMonths[9],
		allMonths[10],
		allMonths[11],
	];
	const week = [allWeekdays[0], allWeekdays[1], allWeekdays[2], allWeekdays[3], allWeekdays[4], allWeekdays[5], allWeekdays[6]];

	let long_date = '';
	let day = date.slice(8, 10);
	let month = date.slice(5, 7);
	let year = date.slice(0, 4);
	let new_date = new Date(date);
	let day_week = new_date.getUTCDay();
	long_date = week[day_week] + ', ' + day + ' ' + months[month - 1] + ' ' + year;

	return long_date;
};
let dateNow = new Date().toISOString().slice(0, 10);

const state = {
	dashboard: [],
	arrivals: [],
	departures: [],
	calendar: [],
	booking: {},
	bookingPayments: {},
	invoiceSettlement: [],
	invoices: [],
	pdf: {},
	ownerCalendar: [],
	ownerProperties: [],
	propietariCalendari: [],
	propietariPreus: [],
	propietariResum: [],
	propietatPreus: [],
	propietariGrafics: [],
	propietariContracte: {},
	propietariCarrecs: {},
	propietariReserves: [],
	propietariReservesDetall: '',
	propietariCarrecsDetall: '',
	propietariCompteDetall: '',
};

const mutations = {
	setDashboard(state, dashboard) {
		state.dashboard = dashboard;
	},
	setArrivals(state, arrivals) {
		state.arrivals = arrivals;
	},
	setDepartures(state, departures) {
		state.departures = departures;
	},
	setCalendar(state, calendar) {
		state.calendar = calendar;
	},
	setBooking(state, booking) {
		state.booking = booking;
	},
	setInvoiceSettlement(state, invoiceSettlement) {
		state.invoiceSettlement = invoiceSettlement;
	},
	setInvoices(state, invoices) {
		state.invoices = invoices;
	},
	setPdf(state, pdf) {
		state.pdf = pdf;
	},
	setOwnerCalendar(state, ownerCalendar) {
		state.ownerCalendar = ownerCalendar;
	},
	setOwnerProperties(state, ownerProperties) {
		state.ownerProperties = ownerProperties;
	},
	setPropietariCalendari(state, propietariCalendari) {
		state.propietariCalendari = propietariCalendari;
	},
	setPropietariPreus(state, propietariPreus) {
		state.propietariPreus = propietariPreus;
	},
	setPropietariResum(state, propietariResum) {
		state.propietariResum = propietariResum;
	},
	setBookingPayments(state, bookingPayments) {
		state.bookingPayments = bookingPayments;
	},
	setPropietatPreus(state, propietatPreus) {
		state.propietatPreus = propietatPreus;
	},
	setPropietariGrafics(state, propietariGrafics) {
		state.propietariGrafics = propietariGrafics;
	},
	setPropietariContracte(state, propietariContracte) {
		state.propietariContracte = propietariContracte;
	},
	setPropietariCarrecs(state, propietariCarrecs) {
		state.propietariCarrecs = propietariCarrecs;
	},
	setPropietariReserves(state, propietariReserves) {
		state.propietariReserves = propietariReserves;
	},
	setPropietariReservesDetall(state, propietariReservesDetall) {
		state.propietariReservesDetall = propietariReservesDetall;
	},
	setPropietariCarrecsDetall(state, propietariCarrecsDetall) {
		state.propietariCarrecsDetall = propietariCarrecsDetall;
	},
	setPropietariCompteDetall(state, propietariCompteDetall) {
		state.propietariCompteDetall = propietariCompteDetall;
	},
};

const actions = {
	// Owner
	getDashboard({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/EntradesSortides', { usr }).then((data) => {
			console.log('EntradesSortides', JSON.parse(data.d).Response);
			commit('setDashboard', JSON.parse(data.d).Response);
		});
	},
	// OwnerArrivals
	getArrivals({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/Entrades', { usr }).then((data) => {
			console.log('Entrades', JSON.parse(data.d).Response);
			commit('setArrivals', JSON.parse(data.d).Response);
		});
	},
	// OwnerDepartures
	getDepartures({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/Sortides', { usr }).then((data) => {
			console.log('Sortides', JSON.parse(data.d).Response);
			commit('setDepartures', JSON.parse(data.d).Response);
		});
	},
	// OwnerCalendar
	getCalendar({ commit }, { usr, data }) {
		postData(dev + '/WebService.asmx/Calendari', { usr, data }).then((data) => {
			console.log('Calendari', JSON.parse(data.d).Response);
			commit('setCalendar', JSON.parse(data.d).Response);
		});
	},
	postBloquejarCalendari({ dispatch }, { usr, data, lodgingId, nits, bloquejar }) {
		let arrayData = data.split('-');
		console.log(usr, data, lodgingId, nits, bloquejar);
		postData(dev + '/WebService.asmx/BloquejarCalendari', {
			usr,
			data,
			lodgingId,
			nits,
			bloquejar,
		}).then((data) => {
			console.log('BloquejarCalendari', data.d);
			if (data.d) {
				dispatch('getPropietariCalendari', { usr: usr, id: lodgingId, month: arrayData[1], year: arrayData[0] });
			}
		});
	},
	postReservaAfegir({ dispatch }, { usr, nom, cognom, telefon, email, adults, joves, bebes, observacions, lodgingId, entrada, nits, preu }) {
		// dispatch
		// datefrom, dateto, country, region, group, owner, properties, management, typology, capacity
		postData(dev + '/WebService.asmx/ReservaAfegir', {
			usr,
			nom,
			cognom,
			telefon,
			email,
			adults,
			joves,
			bebes,
			observacions,
			lodgingId,
			entrada,
			nits,
			preu,
		}).then((data) => {
			console.log('ReservaAfegir', data.d);
			console.log(dispatch);
			//data.d ? dispatch('getCalendars', { usr, datefrom, dateto, country, region, group, owner, properties, management, typology, capacity }) : null;
		});
	},

	// OwnerBookingInfo
	getBooking({ commit }, { usr, reserva }) {
		postData(dev + '/WebService.asmx/ReservaResum', {
			usr,
			reserva,
		}).then((data) => {
			console.log('ReservaResum', JSON.parse(data.d).Response);
			let reservation = JSON.parse(data.d).Response;

			reservation.checkOutInTime = compare(new Date(dateNow), new Date(reservation.departure_date));
			reservation.sumAdultsChildren = parseInt(reservation.adults) + parseInt(reservation.children);

			reservation._arrival_date_format = formatDate(reservation.arrival_date);
			reservation._departure_date_format = formatDate(reservation.departure_date);
			reservation._image_format = `background-image: url(${String(reservation.image)}); 
                                        background-size: cover; 
                                        background-repeat: no-repeat; 
                                        height: 150px;
                                        background-position: center;`;

			commit('setBooking', reservation);
		});
	},
	// Booking_payment
	getBookingPayments({ commit }, { usr, reserva }) {
		postData(dev + '/WebService.asmx/ReservaDetall', { usr, reserva }).then((data) => {
			console.log('ReservaDetall', JSON.parse(data.d).Response);
			commit('setBookingPayments', JSON.parse(data.d).Response);
		});
	},

	//OwnerInvoiceSettlement
	getInvoiceSettlement({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/PropietariCompte', { usr }).then((data) => {
			//console.log('PropietariCompteRAW', data);
			console.log('PropietariCompte', JSON.parse(data.d).Response);
			commit('setInvoiceSettlement', JSON.parse(data.d).Response);
		});
	},
	getPdf({ commit }, { usr, pdf }) {
		postData(dev + '/WebService.asmx/PropietariPdf', { usr, pdf }).then((data) => {
			console.log('FTP', data);
			//let pdf = {name: doc, blob: data.d}
			commit('setPdf', data.d);
		});
	},
	//OwnerInvoice
	getInvoices({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/PropietariFactures', { usr }).then((data) => {
			console.log('PropietariFactures', JSON.parse(data.d).Response);
			commit('setInvoices', JSON.parse(data.d).Response);
		});
	},
	//OwnerSummary
	getPropietariPropietats({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/PropietariPropietats', { usr }).then((data) => {
			console.log('PropietariPropietats', JSON.parse(data.d).Response);
			commit('setOwnerProperties', JSON.parse(data.d).Response);
		});
	},
	getPropietariCalendari({ commit }, { usr, id, month, year }) {
		postData(dev + '/WebService.asmx/PropietariCalendari', {
			usr,
			lodgingId: id,
			month,
			year,
		}).then((data) => {
			console.log('PropietariCalendari', JSON.parse(data.d).Response);
			commit('setPropietariCalendari', JSON.parse(data.d).Response);
		});
	},
	getPropietariPreus({ commit }, { usr, id, month, year }) {
		postData(dev + '/WebService.asmx/PropietariPreus', {
			usr,
			id,
			month,
			year,
		}).then((data) => {
			let json = JSON.parse(String(data.d).replace(',', '')).Response;
			let arrayDias = json[id];
			commit('setPropietariPreus', arrayDias);
			console.log('setPropietariPreus', JSON.parse(String(data.d).replace(',', '')).Response);
		});
	},
	getPropietariResum({ commit }, { usr, any }) {
		postData(dev + '/WebService.asmx/PropietariResum', { usr, any }).then((data) => {
			console.log('raw', data);
			console.log('PropietariResum', JSON.parse(data.d).Response);
			commit('setPropietariResum', JSON.parse(data.d).Response);
		});
	},
	getPropietariContracte({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/PropietariContracte', { usr }).then((data) => {
			console.log('PropietariContracteRaw', data);
			//console.log('PropietariContracte', JSON.parse(data.d).Response);
			commit('setPropietariContracte', data.d);
		});
	},

	//OwnerPrices
	getPropietatPreus({ commit }, { usr, propertyid, month, year }) {
		postData(dev + '/WebService.asmx/PropietatPreus', {
			usr,
			lodgingId: propertyid,
			month,
			year,
		}).then((data) => {
			console.log('PropietatPreus', JSON.parse(data.d).Response);
			commit('setPropietatPreus', JSON.parse(data.d).Response);
		});
	},
	getPropietariGrafics({ commit }, { usr }) {
		postData(dev + '/WebService.asmx/PropietariGrafics', { usr }).then((data) => {
			const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
			const uppercaseWords = (str) => str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());

			let countColor = 0;
			const arrayColors = [
				'#E7925B',
				'#B967E6',
				'#CCE667',
				'#67D5E6',
				'#9FAD6E',
				'#E6C367',
				'#66896A',
				'#67C4A7',
				'#6786E6',
				'#E66E92',
				'#877191',
				'#57749B',
			];

			const propietariGrafics = JSON.parse(data.d).Response;
			let result = {
				donut: {},
				barras: {},
			};

			const sum = (arr) => arr.reduce((a, b) => a + b, 0);
			let donutLabels = [];
			let donutValores = [];
			let donutColores = [];

			let barrasLabels = [];
			let barrasValores = [];
			let barrasColores = [];

			const diccionario = JSON.parse(sessionStorage.getItem('diccionario'));
			Object.entries(propietariGrafics).forEach(([label, valor]) => {
				if (label != 'bookings') {
					switch (label) {
						case 'available_nights':
							donutLabels.push(diccionario.disponibles);
							break;
						case 'blocked_nights':
							donutLabels.push(diccionario.bloquejats);
							break;
						case 'booked_nights':
							donutLabels.push(diccionario.reservats);
							break;
						default:
							donutLabels.push(`${uppercaseWords(label.replace('_', ' '))}`);
					}
					donutValores.push(valor);
					donutColores.push(arrayColors[countColor]);
					countColor++;
				}
				if (label == 'bookings') {
					barrasColores.push(arrayColors[countColor]);
					let contadorMeses = 0;
					valor.forEach((val) => {
						barrasLabels.push(`${capitalize(diccionario.monthNames[contadorMeses])} ${new Date().getFullYear()}`);
						barrasValores.push(+val.import);
						contadorMeses++;
					});
				}
			});

			const sumaValores = sum(barrasValores);
			sumaValores === 0 ? (barrasValores = 0) : null;
			// Donut
			result.donut.labels = donutLabels;
			result.donut.valores = donutValores;
			result.donut.colores = donutColores;
			// Barras
			result.barras.labels = barrasLabels;
			result.barras.valores = barrasValores;
			result.barras.colores = barrasColores;
			console.log(result);
			commit('setPropietariGrafics', result);
		});
	},
	getPropietariCarrecs({ commit }, { usr, gliquidacio, liquidacio, id }) {
		postData(dev + '/WebService.asmx/PropietariCarrecs', {
			usr,
			gliquidacio,
			liquidacio,
			id,
		}).then((data) => {
			console.log('PropietariCarrecs', data);
			console.log('PropietariCarrecs', JSON.parse(data.d).Response);
			commit('setPropietariCarrecs', JSON.parse(data.d).Response);
		});
	},
	getPropietariCarrecsDetall({ commit }, { usr, any, tipologia, format }) {
		commit('setPropietariCarrecsDetall', '');
		postData(dev + '/WebService.asmx/PropietariCarrecsDetall', {
			usr,
			any,
			tipologia,
			format,
		}).then((data) => {
			//console.log('PropietariCarrecsDetall', data.d)
			//console.log('PropietariCarrecsDetall', JSON.parse(data.d).Response)
			commit('setPropietariCarrecsDetall', data.d);
		});
	},
	getPropietariCompteDetall({ commit }, { usr, any }) {
		postData(dev + '/WebService.asmx/PropietariCompteDetall', {
			usr,
			any,
		}).then((data) => {
			//console.log('PropietariCompteDetall', data)
			//console.log('PropietariCompteDetall', data.d)
			commit('setPropietariCompteDetall', data.d);
		});
	},

	// Owners Reservas pasadas
	getPropietariReserves({ commit }, { usr, any }) {
		postData(dev + '/WebService.asmx/PropietariReserves', { usr, any }).then((data) => {
			//console.log('PropietariReserves', data)
			console.log('PropietariReserves', JSON.parse(data.d).Response);
			commit('setPropietariReserves', JSON.parse(data.d).Response);
		});
	},
	getPropietariReservesDetall({ commit }, { usr, any }) {
		postData(dev + '/WebService.asmx/PropietariReservesDetall', {
			usr,
			any,
		}).then((data) => {
			commit('setPropietariReservesDetall', data.d);
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
