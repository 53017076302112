import Vue from "vue";
import VueRouter from "vue-router";
// import postData from '../assets/js/fetch.js';
import Home from "../views/Home.vue";

// let dev;
// process.env.NODE_ENV === 'production' ? dev = "" : dev = "https://xapp.icnea.net";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: "Login" },
    component: Home,
  },

  //// Checkin ////
  {
    path: "/inout.html",
    name: "Inout",
    meta: { title: "Check-in app" },
    component: () =>
      import(/* webpackChunkName: "Inout" */ "../views/checkin/Inout.vue"),
  },
  {
    path: "/arrivals",
    name: "Arrivals",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "Arrivals" */ "../views/checkin/Arrivals.vue"
      ),
  },
  {
    path: "/departures",
    name: "Departures",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "Departures" */ "../views/checkin/Departures.vue"
      ),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "Calendar" */ "../views/checkin/Calendar.vue"
      ),
  },
  {
    path: "/booking/:bookingId",
    name: "Booking",
    meta: { title: "Check-in app" },
    component: () =>
      import(/* webpackChunkName: "Booking" */ "../views/checkin/Booking.vue"),
  },
  {
    path: "/booking-details/:bookingId",
    name: "BookingDetails",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "BookingDetails" */ "../views/checkin/Booking_details.vue"
      ),
  },
  {
    path: "/booking-guests/:bookingId",
    name: "BookingGuests",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "BookingGuests" */ "../views/checkin/Booking_guests.vue"
      ),
  },
  {
    path: "/booking-payment/:bookingId",
    name: "BookingPayment",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "BookingPayment" */ "../views/checkin/Booking_payment.vue"
      ),
  },
  {
    path: "/booking-contract/:bookingId",
    name: "BookingContract",
    meta: { title: "Check-in app" },
    component: () =>
      import(
        /* webpackChunkName: "BookingContract" */ "../views/checkin/Booking_contract.vue"
      ),
  },

  //// Guest ////
  {
    path: "/guest.html",
    name: "Guest",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest.vue"),
  },
  {
    path: "/guest_:id",
    name: "GuestParam",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest.vue"),
  },
  {
    path: "/info",
    name: "GuestInfo",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_info.vue"),
  },
  {
    path: "/instructions/:instruction",
    name: "GuestInstructions",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_instructions.vue"
      ),
  },
  {
    path: "/terms",
    name: "GuestTerms",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_terms.vue"),
  },
  {
    path: "/details",
    name: "GuestDetails",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_details.vue"
      ),
  },
  {
    path: "/guests",
    name: "GuestGuests",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_guests.vue"),
  },
  {
    path: "/payment",
    name: "GuestPayment",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_payment.vue"
      ),
  },
  {
    path: "/contract",
    name: "GuestContract",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_contract.vue"
      ),
  },
  {
    path: "/chat",
    name: "GuestChat",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_chat.vue"),
  },
  {
    path: "/key",
    name: "GuestKey",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_key.vue"),
  },
  {
    path: "/activities",
    name: "GuestActivities",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_activities.vue"
      ),
  },
  {
    path: "/activity/:id",
    name: "GuestActivity",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_activity.vue"
      ),
  },
  {
    path: "/expired",
    name: "GuestExpired",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_expired.vue"
      ),
  },
  {
    path: "/review",
    name: "GuestReviews",
    meta: { title: "Guest app" },
    component: () =>
      import(/* webpackChunkName: "Guest" */ "../views/guest/Guest_review.vue"),
  },
  {
    path: "/reservation/:reserva",
    name: "GuestReservation",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_reservation.vue"
      ),
  },
  {
    path: "/order-summary",
    name: "GuestOrderSumary",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_order_summary.vue"
      ),
  },
  {
    path: "/supplements",
    name: "GuestSupplements",
    meta: { title: "Guest app" },
    component: () =>
      import(
        /* webpackChunkName: "Guest" */ "../views/guest/Guest_supplements.vue"
      ),
  },

  //// Service ////
  {
    path: "/service.html",
    name: "Service",
    meta: { title: "Maintenance app" },
    component: () =>
      import(/* webpackChunkName: "Service" */ "../views/service/Service.vue"),
  },
  {
    path: "/service-detail/:service",
    name: "ServiceDetail",
    meta: { title: "Maintenance app" },
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/service/Service_detail.vue"
      ),
  },
  {
    path: "/service-calendar",
    name: "ServiceCalendar",
    meta: { title: "Maintenance app" },
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/service/Service_calendar.vue"
      ),
  },
  {
    path: "/service-calendar-info/:bookingId",
    name: "ServiceCalendarInfo",
    meta: { title: "Maintenance app" },
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/service/Service_calendar_info.vue"
      ),
  },

  //// Owner ////
  {
    path: "/owner.html",
    name: "Owner",
    meta: { title: "Owners app" },
    component: () =>
      import(/* webpackChunkName: "Owner" */ "../views/owner/Owner.vue"),
  },
  {
    path: "/owner-arrivals",
    name: "OwnerArrivals",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerArrivals.vue"
      ),
  },
  {
    path: "/owner-departures",
    name: "OwnerDepartures",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerDepartures.vue"
      ),
  },
  {
    path: "/owner-booking-info/:bookingId",
    name: "OwnerBookingInfo",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerBookingInfo.vue"
      ),
  },
  {
    path: "/owner-calendar",
    name: "OwnerCalendar",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerCalendar.vue"
      ),
  },
  {
    path: "/owner-invoices",
    name: "OwnerInvoices",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerInvoices.vue"
      ),
  },
  {
    path: "/owner-invoice-settlement",
    name: "OwnerInvoiceSettlement",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerInvoiceSettlement.vue"
      ),
  },
  {
    path: "/owner-summary",
    name: "OwnerSummary",
    meta: { title: "Owners app" },
    component: () =>
      import(/* webpackChunkName: "Owner" */ "../views/owner/OwnerSummary.vue"),
  },
  {
    path: "/owner-resum",
    name: "OwnerResum",
    meta: { title: "Owners app" },
    component: () =>
      import(/* webpackChunkName: "Owner" */ "../views/owner/OwnerResum.vue"),
  },
  {
    path: "/owner-prices",
    name: "OwnerPrices",
    meta: { title: "Owners app" },
    component: () =>
      import(/* webpackChunkName: "Owner" */ "../views/owner/OwnerPrices.vue"),
  },
  {
    path: "/owner-reservations",
    name: "OwnerReservations",
    meta: { title: "Owners app" },
    component: () =>
      import(
        /* webpackChunkName: "Owner" */ "../views/owner/OwnerReservations.vue"
      ),
  },

  //// Cleaning ////
  {
    path: "/cleaning.html",
    name: "Cleaning",
    meta: { title: "Cleaning app" },
    component: () =>
      import(
        /* webpackChunkName: "cleaning" */ "../views/cleaning/Cleaning.vue"
      ),
  },
  {
    path: "/cleaning-information/:cleaningDate/:bookingId",
    name: "CleaningDetails",
    meta: { title: "Cleaning app" },
    component: () =>
      import(
        /* webpackChunkName: "cleaning" */ "../views/cleaning/CleaningDetails.vue"
      ),
  },
  {
    path: "/cleaning-calendar",
    name: "CleaningCalendar",
    meta: { title: "Cleaning app" },
    component: () =>
      import(
        /* webpackChunkName: "cleaning" */ "../views/cleaning/CleaningCalendar.vue"
      ),
  },

  ///////////////// Manager /////////////////
  {
    path: "/manager.html",
    name: "Dashboard",
    meta: { title: "Manager app" },
    component: () =>
      import(/* webpackChunkName: "pms" */ "../views/manager/Dashboard.vue"),
  },
  // Manager checkin //
  {
    path: "/manager-bookings",
    name: "ManagerBookings",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBookings.vue"
      ),
  },
  {
    path: "/manager-arrivals",
    name: "ManagerArrivals",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerArrivals.vue"
      ),
  },
  {
    path: "/manager-departures",
    name: "ManagerDepartures",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerDepartures.vue"
      ),
  },
  {
    path: "/manager-booking/:bookingId",
    name: "ManagerBooking",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBooking.vue"
      ),
  },
  {
    path: "/manager-booking-details/:bookingId",
    name: "ManagerBookingDetails",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBookingDetails.vue"
      ),
  },
  {
    path: "/manager-booking-guests/:bookingId",
    name: "ManagerBookingGuests",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBookingGuests.vue"
      ),
  },
  {
    path: "/manager-booking-payment/:bookingId",
    name: "ManagerBookingPayment",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBookingPayment.vue"
      ),
  },
  {
    path: "/manager-booking-contract/:bookingId",
    name: "ManagerBookingContract",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerBookingContract.vue"
      ),
  },
  {
    path: "/manager-checkin-calendar",
    name: "ManagerCheckinCalendar",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerCheckinCalendar.vue"
      ),
  },
  {
    path: "/manager-availability",
    name: "ManagerAvailability",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/inout/ManagerAvailability.vue"
      ),
  },

  // Manager Cleaning //
  {
    path: "/manager-cleaning.html",
    name: "ManagerCleaning",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/cleaning/ManagerCleaning.vue"
      ),
  },
  {
    path: "/manager-cleaning-information/:cleaningDate/:bookingId",
    name: "ManagerCleaningDetails",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/cleaning/ManagerCleaningDetails.vue"
      ),
  },
  {
    path: "/manager-cleaning-calendar",
    name: "ManagerCleaningCalendar",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/cleaning/ManagerCleaningCalendar.vue"
      ),
  },

  // Manager Service //
  {
    path: "/manager-service.html",
    name: "ManagerService",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/service/ManagerService.vue"
      ),
  },
  {
    path: "/manager-service-detail/:service",
    name: "ManagerServiceDetail",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/service/ManagerServiceDetail.vue"
      ),
  },
  {
    path: "/manager-service-calendar",
    name: "ManagerServiceCalendar",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/service/ManagerServiceCalendar.vue"
      ),
  },
  {
    path: "/manager-service-calendar-info/:bookingId",
    name: "ManagerServiceCalendarInfo",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/service/ManagerServiceCalendarInfo.vue"
      ),
  },

  // Manager Chat //
  {
    path: "/manager-list-chats",
    name: "ManagerListChats",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/chat/ManagerListChats.vue"
      ),
  },
  {
    path: "/manager-chat/:id",
    name: "ManagerChat",
    meta: { title: "Manager app" },
    component: () =>
      import(
        /* webpackChunkName: "pms" */ "../views/manager/chat/ManagerChat.vue"
      ),
  },
]; //testear esto en las rutas

/* webpackPrefetch: true */ // const currentPath  = window.location.pathname;
// const currentQueryParams = window.location.search;

const router = new VueRouter({
  mode: "history",
  //linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const defaultTitle = "Guest app";
  document.title = to.meta.title || defaultTitle;
  next();
});
// router.beforeEach((to, from, next) => {

//     if (to.meta.title == 'Guest app') {
//         const usr = String(to.query.id).replace(/[ ]/g, '+')
//         postData(dev + "/WebService.asmx/Usuari", { usr })
//         .then(( data ) => {
//             const user = JSON.parse(data.d).Response

//             postData(dev + "/WebService.asmx/ReservaResum", { usr, reserva: user.user_booking })
//             .then(( book )=> {
//                 const booking = JSON.parse(book.d).Response

//                 // Title
//                 const dominio = user.user_app
//                 const titular = String(dominio.split('.')[1]).toUpperCase() || to.meta.title
//                document.title = titular

//                 // const op = `{"dominio": "${dominio}", "img": "${translator.logo}", "url": "${dominio}${to.fullPath}"}`;
//                 // sessionStorage.setItem('op', openGraphTags)

//                 // Favicon
//                 const favicon32 = document.querySelector('link[href="/img/icons/favicon-32x32.png"]');
//                 const favicon16 = document.querySelector('link[href="/img/icons/favicon-16x16.png"]');
//                 if (favicon32) {
//                     favicon32.href = translator.logo || '/img/icons/favicon-32x32.png';
//                 }
//                 if (favicon16) {
//                     favicon16.href = translator.logo || '/img/icons/favicon-16x16.png';
//                 }

//                 // const ogUrlMeta = document.querySelector('meta[property="og:url"]');
//                 // const ogTitleMeta = document.querySelector('meta[property="og:title"]');
//                 // const ogImgMeta = document.querySelector('meta[property="og:image"]');
//                 // ogUrlMeta ? ogUrlMeta.setAttribute('content', `${dominio}${to.fullPath}`) : null;
//                 // ogTitleMeta ? ogTitleMeta.setAttribute('content', `Check-in app`) : null;
//                 // ogImgMeta ? ogImgMeta.setAttribute('content', `${translator.logo}`) : null;

//                 // const twDomainMeta = document.querySelector('meta[property="twitter:domain"]');
//                 // const twUrlMeta = document.querySelector('meta[property="twitter:url"]');
//                 // const twTitleMeta = document.querySelector('meta[name="twitter:title"]');
//                 // const twImgMeta = document.querySelector('meta[name="twitter:image"]');
//                 // twDomainMeta ? twDomainMeta.setAttribute('content', `${dominio}`) : null;
//                 // twUrlMeta ? twUrlMeta.setAttribute('content', `${dominio}${to.fullPath}`) : null;
//                 // twTitleMeta ? twTitleMeta.setAttribute('content', `Check-in app`) : null;
//                 // twImgMeta ? twImgMeta.setAttribute('content', `${translator.logo}`) : null;

//                 next()

//             })
//             .catch(err => console.log(err));
//         })
//         .catch ((err)=>  console.error(err));
//     } else {
//         next()
//     }
// })

export default router;
